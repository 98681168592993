<template>
  <div class="w100p pl10 pr10" >
   <div class="textc mb20">
     <p class="textc fs9  fwbold">分类模块</p>
   </div>

   <p class="fs8 mt10 mb10 fwbold">
      展示样式
    </p>
    <div class="d-flex flex-wrap-y">
      <div class="ml10" v-for="(item,index) in listData.list" :key="index" @click="currentTab = item">
        <img :src="item.img" v-if="item.img" class="w70 h70 ml10 mr10">
      </div>
    </div>
    <div class="">
      <p class="fs8 mt10 mb10 fwbold">
        样式设置
      </p>
     <div class="ml20">
        <el-upload
                  accept="image/jpeg,image/png"
                  class="addTabIconTap"
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="bannerSuccess"
                  :before-upload="beforeUploadImageDynamicPic">
              <img v-if="currentTab.img" :src="currentTab.img" class="w100p h100p">
              <i v-else class="el-icon-plus"></i>

        </el-upload>
        <p class="fs6 mb10" v-if="size">图标上传宽度为{{size}}px的效果最佳</p>
        <p class="fs6 mb10" v-else>图标上传宽度为100*100px的效果最佳</p>
     </div>
      <!-- <p class="fs8  mb10 " @click="">恢复默认样式</p> -->
      <p class="fs8 mt10 mb10 fwbold">
        标题设置
      </p>
      <el-input :placeholder="currentTab.title" v-model="currentTab.title" maxlength="4" show-word-limit class="mb10 w200 ml20"></el-input>
      <p class="fs8 mt10 mb10 fwbold">
        跳转设置
      </p>
      <el-select
        v-model="currentTab.name"
        class="ml20 mb20"
        style="width: 150px;"
        placeholder="请选择"
        @change="select_tap($event)"
      >
        <el-option
          v-for="(v) in optionList"
          :key="v.name"
          :label="v.name"
          :disabled="v.disabled"
          :value="v.name"
        />
      </el-select>

    </div>
    <div class="ml20">
      <el-button type="primary" class="" @click="reset_tab_tap">
        图标重置
      </el-button>
    </div>


  </div>
</template>

<script>

import {
  selectGoodsTypeInfoPC,
} from "@/api/coupon.js";
import { getDataTimeSec } from "@/utils";
import commonTable from "@/components/common/commonTable";
export default {
  props:['listData','size'],
  data() {
    return {
      currentTab:'',
      optionList:[{
        name:'无'
      }],
      uploadUrl:this.$store.state.uploadingUrl,
      ossUrl:this.$store.state.ossUrl,
      oldTab:{
        list:[{
          img:'https://idea-csmp-1253958260.cos.ap-beijing.myqcloud.com/yimai_photos/ecommerce/tab1.png',

          title:'衣服'
        },{
          img:'https://idea-csmp-1253958260.cos.ap-beijing.myqcloud.com/yimai_photos/ecommerce/tab2.png',
          title:'裤子'
        },{
          img:'https://idea-csmp-1253958260.cos.ap-beijing.myqcloud.com/yimai_photos/ecommerce/tab3.png',
          title:'帽子'
        },{
          img:'https://idea-csmp-1253958260.cos.ap-beijing.myqcloud.com/yimai_photos/ecommerce/tab4.png',
          title:'鞋子'
        }],

        type:'tab'
      }
    };
  },
  watch:{
    listData:{
      handler(val){
        console.log(val)
        this.currentTab = val.list[0]
      },
      immediate:true
    }
  },
  created(){
   this.queryClassify()
  },
  methods:{

    queryClassify(){
      let data = {
        pageSize: 999
      }
      selectGoodsTypeInfoPC(data)
      .then(res=>{
        if(res.data){
          this.optionList = res.data.pageInfo.list
          this.optionList.unshift({
            name:'无'
          })
        }else{
          this.optionList = []
        }
      })
      .catch(err=>{

      })
    },
    restTab(){

    },
    select_tap(e){
      let than = this
      than.optionList.forEach(item=>{
        console.log(item)
        if(item.name == than.currentTab.name){
          than.currentTab.goodstypeId = item.goodstypeId
          item.disabled = true
        }
      })
      console.log(this.currentTab)
    },
    bannerSuccess(res){
      //动态图上传成功
      // if(!obj.type){
      //   var index = obj.index;
      //   this.$set(this.mallBanner[index],'img',this.ossUrl + '/' + res.data)
      // }else{
      //   var index = obj.index;
      //   this.$set(this.appointmentBanner[index],'img',this.ossUrl + '/' + res.data)
      // }
      this.currentTab.img = this.ossUrl + '/' + res.data

    },
    beforeUploadImageDynamicPic(file){
      var _this = this;
      var isLt10M = file.size / 1024 / 1024  < 10;
      if (['image/jpeg', 'image/png'].indexOf(file.type) == -1) {
          _this.$message.error('请上传正确的图片格式');
          return false;
      }
      if (!isLt10M) {
          _this.$message.error('上传图片大小不能超过10MB哦!');
          return false;
      }
    },
    reset_tab_tap(){
      this.optionList.forEach(item=>{
        item.disabled = false
      })
      this.$emit('reset_tab_tap',this.oldTab)
    }
  }
};
</script>

<style lang="scss" scoped>
 /deep/.addTabIconTap .el-upload--text{
  width: 70px;
  height: 70px;
  line-height: 70px;
}
/deep/ .addTabIconTap .el-upload-list__item {
  width: 70px;
  height: 70px;
  line-height: 70px;
}
</style>
